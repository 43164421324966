import React from 'react'
import Cookies from 'js-cookie'

const ResultsPerPageFilter = ({ options, ResultsPerPage, setResultsPerPage, setResultsPerPageSize, Cookie_expiry_time,t }) => {

  const listOptions = options.map((number, index) =>
  <option key={`options ${index}`} value={number}>{number}</option>
    );
    let X = 0;
  const test = options.map((num) => X = num)
  return (
    <div className="ResultsPerPageFilter">
       {t("Show")}: <select className='orthoform' value={ResultsPerPage} onChange={e => { setResultsPerPageSize(parseInt( e.target.value)); 
        Cookies.set('resultsPerPageSize',parseInt( e.target.value),{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
       setResultsPerPage( parseInt( e.target.value));}}>
                  {listOptions}
            </select> 
          
           
    </div>
    
    
  )
}

export default ResultsPerPageFilter
