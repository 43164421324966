import getFilterValueDisplay from "./view-helpers/getFilterValueDisplay"
import React from "react";

function uniqueByKey(array, value) {
    return array.filter((e, i) => array.findIndex(a => a[value] === e[value]) === i);
}

function TechdocsFilter(props) {
    const { className, label, onMoreClick, onRemove, onSelect, options, showMore, showSearch, onSearch, searchPlaceholder, docClass, optionClickRemove, optionClickAdd, optionsMap, t } = props?.filterProps
    // const diffOptions = () => {
    //     //console.log('function called diffoptions ', docClass  + ' options ' + options)
    //     if (docClass === undefined && options !== undefined)
    //         return options;
    //     if (options === undefined & docClass
    //         !== undefined)
    //         return docClass
    //             ;
    //     if (docClass
    //         === undefined || (docClass && docClass.length === 0))
    //         return []
    //     if (options.length === docClass.length)
    //         return options;

    //     var newOptions = []
    //     //console.log('isArray ', Array.isArray(options))
    //     //console.log('isMap ', options instanceof Map)
    //     options.forEach(o => {
    //         docClass
    //             .forEach(d => {
    //                 if (o.value === d.value)
    //                     newOptions[newOptions.length] = o;
    //                 else
    //                     newOptions[newOptions.length] = d;
    //             })
    //     })
    //     // newOptions.forEach(n => {
    //     //     console.log('n.value=' + n.value + ' n.count=' + n.count + ' n.selected=' + n.selected)
    //     // })
    //     newOptions = uniqueByKey(newOptions, "value")
    //     newOptions.sort((a, b) => (a.value > b.value) ? 1 : -1)
    //     return newOptions;
    // }


    var newOptions = options//diffOptions();
    return (
        <div style={{borderBottom: '5px solid #F3F6F7', padding: '30px 0'}} className="card-body cat-title">
            <h3 style={{display: 'block'}}>{label} </h3>
            {newOptions.length < 1 && <div>No matching options</div>}
            {newOptions.map(option => {
                const checked = option.selected;
                let altDispValue = optionsMap[option.value]!==undefined ? optionsMap[option.value] : t(option.value)
                if(altDispValue===undefined||altDispValue.length===0)
                 altDispValue=t('Other');                
                return (
                    <label
                        key={`${getFilterValueDisplay(option.value)}`}
                        htmlFor={`example_facet_${label}${getFilterValueDisplay(
                            t(option.value)
                        )}`}
                        className="checkmark-wrap"
                    >
                        {altDispValue} [{option.count}]
                        <input
                            id={`example_facet_${label}${getFilterValueDisplay(
                                t(option.value)
                            )}`}
                            type="checkbox"
                            data-cat={getFilterValueDisplay(option.value)}
                            checked={checked}
                            onChange={() =>{
                                // if(checked){
                                    // props?.clearFilters(["regioncountrylist","language","audience","usage_type", "expirationdate", "document_classification", "business_line"])
                                    // props?.removeFilter("document_classification_subcategory");
                                    // props?.removeFilter("reagentlist");
                                    // props?.removeFilter("equipmentlist");
                                // }
                                checked ? onRemove(option.value) : onSelect(option.value)
                            }}
/*                            onChange={() => {
                                checked ? onRemove(option.value) : onSelect(option.value)
                                checked ? optionClickRemove(option) : optionClickAdd(option)
                            }}*/
                        />
                        <span className="checkmark">
                        </span>
                    </label>
                    
                )
            })}
            {showMore && <div className="sui-multi-checkbox-facet__view-more" onClick={onMoreClick}>+ {t('More')}</div>}
        </div>
    )
}

export default TechdocsFilter
