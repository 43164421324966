
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { getConfig } from '../config/config-helper';

import { BiGlasses } from 'react-icons/bi';
import { FaFileDownload } from 'react-icons/fa';
import { trackDocData } from '../utils/utils';

// import styles from '../assets/css/DocumentClassification.Module.css'
// import Loader from "../components/Loader"

var ElasticAppSearch = require("@elastic/app-search-javascript");

const { public_url, auth_url, search_key, /*, TRACKING_ID */ } = getConfig();

const GetCompoundDoc = ({ compoundDoc, engineName, downloadDoc, setLoading, t, documentView }) => {
    const documentsClick = "documents_click";
    const compoundClick = "compound_click";
    
    const [compoundDocs, setCompoundDocs] = useState([]);
    const fileTypeobject = {
        property: ["pdf", "docx", "doc", "pptx", "xlsx", "xls", "msg", "ppt", "zip", "xlsm"]
    }
    useEffect(() => {
        var key = Cookies.get("at") != null ? Cookies.get("at") : search_key;
        var url = Cookies.get("at") != null ? auth_url : public_url;
        // setLoading(true);
        var client = ElasticAppSearch.createClient({ searchKey: key, endpointBase: url, engineName: engineName });
        client.search("", {
            page: { size: 100 },
            filters: { all: [{ parenttype: "CompoundDocument" }, { parentid: compoundDoc.parentid.raw }] },
            result_fields: { name: { raw: {} }, title: { raw: {} }, filename: { raw: {} }, datasize: { raw: {} }, filetype: { raw: {} }, parentid: { raw: {} }, parenttype: { raw: {} }, id: { raw: {} }, recordid: {raw: {}}, revision: { raw: {} } },
        }).then(resultList => {
            const newData = [];
            resultList.results.forEach((element) => {
                // Getting the file name without the extension
                const fileName = element.data.name.raw.split(".")[0];
                
                if (fileName !== compoundDoc.recordid.raw) {
                    // if (!element.data.name.raw.includes(compoundDoc.recordid.raw)) {
                    if (fileTypeobject.property.includes(element.data.filetype.raw.toLowerCase())) {
                        newData.push(element);
                    }
                    else {
                        // console.log(element.data.name.raw, "File extension not in extesions-array!")
                    }
                }
                else {
                    // console.log(element.data.name.raw, "File name matching with the Name / Label!")
                }
            });

            const newArry = createGroups(newData, 2);
            setCompoundDocs(newArry);
            // setLoading(false);
        })
            .catch(error => {
                // setLoading(false);
            })
    }, [])
    function createGroups(arr, numGroups) {
        const perGroup = Math.ceil(arr.length / numGroups);
        return new Array(numGroups)
            .fill('')
            .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
    }


    return (

        <div style={{ marginBottom: '0px', marginLeft: '35px', paddingBottom: '4px' }}>
            {/* Display Compound Doc Left Side */}
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr' }}>
                <div>
                    {compoundDocs[0]?.map((item, index) => (
                        <>
                            <p key={`com-${index}`}>
                                {
                                    item?.data?.filetype?.raw?.toLowerCase() === "pdf" 
                                    ?
                                    <a 
                                        className='compoundDoc' 
                                        style={{ textDecoration: 'underline', font: 'normal normal normal 14px/21px Rubik', wordBreak: 'break-all' }} 
                                        href='#' 
                                        onClick={(evt) => { 
                                            setLoading(false); 
                                            documentView(item.data.id.raw, item.data.filetype.raw, item.data.name.raw, evt, 'E', Cookies.get('at'), setLoading, false, "", compoundClick); 
                                            trackDocData(item?.data?.id?.raw, item?.data?.title?.raw, item?.data?.recordid?.raw, compoundClick, item?.data?.revision?.raw);
                                        }}
                                    >
                                        {item.data.name.raw}
                                    </a>
                                :
                                    <a 
                                        className='compoundDoc' 
                                        style={{ textDecoration: 'underline', font: 'normal normal normal 14px/21px Rubik', wordBreak: 'break-all' }} 
                                        href='#' 
                                        onClick={(evt) => { 
                                            setLoading(false);
                                            downloadDoc(item.data.id.raw, item.data.filetype.raw, item.data.name.raw, evt, 'E', Cookies.get('at'), setLoading, false, "", compoundClick); 
                                            trackDocData(item?.data?.id?.raw, item?.data?.title?.raw, item?.data?.recordid?.raw, compoundClick, item?.data?.revision?.raw);
                                        }}
                                    >
                                        {item.data.name.raw}
                                    </a>
                                }
                                
                                <a href='#' style={{ paddingLeft: '15px' }} 
                                    onClick={(evt) => { 
                                        setLoading(false); 
                                        downloadDoc(item.data.id.raw, item.data.filetype.raw, item.data.name.raw, evt, 'E', Cookies.get('at'), setLoading, false, "", compoundClick); 

                                        trackDocData(item?.data?.id?.raw, item?.data?.title?.raw, item?.data?.recordid?.raw, compoundClick, item?.data?.revision?.raw);
                                    }}
                                >
                                    {/* <BiGlasses title={t('View Document')}/> */}
                                    <FaFileDownload className='indigo' style={{ cursor: 'pointer' }} />
                                </a>
                            </p>

                        </>
                    ))}
                </div>
                {/* Display Compound Docs Right Side */}
                <div>
                    {compoundDocs[1]?.map((item, index) => (
                        <>
                            <p key={`comp-${index}`}>
                                {
                                    item?.data?.filetype?.raw?.toLowerCase() === "pdf"
                                    ?
                                        <a 
                                            style={{ textDecoration: 'underline', font: 'normal normal normal 14px/21px Rubik', wordBreak: 'break-all' }} 
                                            href='#' 
                                            onClick={(evt) => { 
                                                setLoading(false); 
                                                documentView(item.data.id.raw, item.data.filetype.raw, item.data.name.raw, evt, 'E', Cookies.get('at'), setLoading, false, "", compoundClick); 
                                                trackDocData(item?.data?.id?.raw, item?.data?.title?.raw, item?.data?.recordid?.raw, compoundClick, item?.data?.revision?.raw);
                                            }}
                                        >
                                            {item.data.name.raw}
                                        </a>
                                    :
                                        <a 
                                            style={{ textDecoration: 'underline', font: 'normal normal normal 14px/21px Rubik', wordBreak: 'break-all' }} 
                                            href='#' 
                                            onClick={(evt) => { 
                                                setLoading(false); 
                                                downloadDoc(item.data.id.raw, item.data.filetype.raw, item.data.name.raw, evt, 'E', Cookies.get('at'), setLoading, false, "", compoundClick); 
                                                trackDocData(item?.data?.id?.raw, item?.data?.title?.raw, item?.data?.recordid?.raw, compoundClick, item?.data?.revision?.raw);
                                            }}
                                        >
                                            {item.data.name.raw}
                                        </a>
                                }
                                    <a href='#' style={{ paddingLeft: '15px' }} 
                                        onClick={(evt) => { 
                                                setLoading(false); 
                                                downloadDoc(item.data.id.raw, item.data.filetype.raw, item.data.name.raw, evt, 'E', Cookies.get('at'), setLoading, false, "", compoundClick);
                                                trackDocData(item?.data?.id?.raw, item?.data?.title?.raw, item?.data?.recordid?.raw, compoundClick, item?.data?.revision?.raw); 
                                            }}
                                    >
                                        {/* <BiGlasses title={t('View Document')}/> */}
                                        <FaFileDownload className='indigo' style={{ cursor: 'pointer' }} />
                                    </a>
                                
                            </p>

                        </>
                    ))}
                </div>
            </div>

        </div>
    );
}


export default GetCompoundDoc;