import PropTypes from "prop-types";
import React, { useState } from "react";
import Select, { components } from "react-select";

import getFilterValueDisplay from "./view-helpers/getFilterValueDisplay"
import appendClassName from "./view-helpers/appendClassName"
import Cookies from "js-cookie";
// import { Form,  Select } from 'antd';

function Option(props) {
  return (
    <components.Option {...props}>
      <span className="sui-select__option-label">{props.data.label}</span>
    </components.Option>
  );
}

Option.propTypes = {
  data: PropTypes.object.isRequired
};

function toSelectBoxOption(filterValue) {
  return {
    value: filterValue.value,
    label: getFilterValueDisplay(filterValue.value),
    count: filterValue.count
  };
}

function initialState(filterValue,cmpnt,t, isEmployee)
{
  // if(!Cookies.get(cmpnt))
  //  return ''
  let initValue = Cookies.get(cmpnt)
  initValue = initValue?.trim() ? initValue : ( isEmployee ? "ALL" : "")
  return initValue ?
                    {
                      value: initValue,
                      label: getFilterValueDisplay(t(initValue)),
                      count: 0
                    }
                    :
                    ''
}

const setDefaultStyle = {
  option: () => ({}),
  control: () => ({}),
  dropdownIndicator: () => ({}),
  indicatorSeparator: () => ({})
};

function OrthoSelectFacet({ isEmployee, className, label, onChange, options, cmpnt, cookieName,load,setLoading,t }) {
  //let selectedSelectBoxOption;
  const [selectedSelectBoxOption, setSelectedSelectBoxOption] = useState(initialState(options[0],cookieName,t, isEmployee));
  //const [selectedSelectBoxOption, setSelectedSelectBoxOption] = useState();
  //let isSelectedSelectBoxOptionSet = false;
  //console.log('options received ', options)
  // const [form] = Form.useForm();
  const selectBoxOptions = options.filter(o => o!==null).map(option => {
    const selectBoxOption = toSelectBoxOption(option);
    return selectBoxOption;
  });

  return (
    <div className={appendClassName("sui-facet", className)}>
      <div className="sui-facet_lang_country_title">{label}</div>
      {/* <Form form={form}
                name="basic"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
               
                autoComplete="off"
            >
      <Select
      defaultValue={selectedSelectBoxOption}
      isLoading={load}
      components={{ Option }}
      onChange={o => {
        let selectBoxOption = { value: o.value, label: getFilterValueDisplay(t(o.value)), count: 0 }
        let filter = { field: cmpnt, type: "any", values: o.value }
        setSelectedSelectBoxOption(selectBoxOption)
        onChange(o.value)
      }}
      options={selectBoxOptions}
      isSearchable={true}
      style={{ width: 180 }}
      styles={setDefaultStyle} />
      
      </Form> */}
      <Select
        className="sui-select"
        classNamePrefix="sui-select"
        components={{ Option }}
        value={selectedSelectBoxOption}
        isLoading={load}
        onChange={o => {
          let selectBoxOption = { value: o.value, label: getFilterValueDisplay(t(o.value)), count: 0 }
          let filter = { field: cmpnt, type: "any", values: o.value }
          setSelectedSelectBoxOption(selectBoxOption)
          onChange(o.value)
        }}
        options={selectBoxOptions}
        isSearchable={true}
        styles={setDefaultStyle}
        maxMenuHeight={350}
      />
    </div>
  );
}

OrthoSelectFacet.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};


export default OrthoSelectFacet




