import React, { useState, createContext } from 'react';
import Modal from 'react-modal';

import { DetailsModal } from '../components/Documents';

const ModalContext = createContext({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
  modalContent: '',
  updateModalContent: () => {},
  userLoggedIn: false,
  siblings: () => {},
});

const ModalProvider = ({ children }) => {
  const [isModalOpen, setModalState] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [userLoggedIn,setUserLoggedIn] = useState(false);
  const [siblings,setSiblings] = useState([])
  const [altDispValues,setAltDispValues] = useState([])

  const openModal = () => setModalState(true);
  const closeModal = () => setModalState(false);
  const updateModal = document => setModalContent(document);
  const updatedUserStatus = updatedStatus => setUserLoggedIn(updatedStatus);
  const updateSiblings = siblings => setSiblings(siblings);
  const updateAltDispValues = altDispValues => setAltDispValues(altDispValues)

  const contextProvider = {
    isModalOpen,
    openModal,
    closeModal,
    modalContent,
    updateModal,
    updatedUserStatus,
    updateSiblings,
    updateAltDispValues,
  };

  return (
    <ModalContext.Provider value={contextProvider}>
      {children}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
        contentLabel="Document Details" >
        {modalContent && <DetailsModal document={modalContent} closeModal={closeModal} isEmployed={userLoggedIn} 
        siblingsy={siblings} key={`docModalContent-${Math.floor(Math.random() * 100000000)}`} altDispValues={altDispValues}/>}
      </Modal>
    </ModalContext.Provider>
  );
};

Modal.setAppElement('#root');

export { ModalContext, ModalProvider };
