import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
//import reportWebVitals from './reportWebVitals';
import LanguageDetector from 'i18next-browser-languagedetector'
import TagManager from 'react-gtm-module'
import 'bootstrap/dist/js/bootstrap.js'


import App from './App'
import {getConfig} from "./config/config-helper";
import { BrowserRouter } from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.min.css'
import 'flag-icon-css/css/flag-icons.min.css'

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en-global', 'en-us', 'fr-dz', 'en-au', 'en-be', 'pt-br', 'en-ca', 'fr-ca', 'zh-cn', 'en-eg', 'fr-fr', 'de-de', 'en-in', 'it-it', 'ja-jp', 'latam', 'en-mea', 'fr-mea', 'en-nl', 'en-au', 'pt-pt', 'en-pr', 'ru-ru', 'en-sa', 'en-sv', 'en-sg', 'es-es', 'en-za', 'ko-kr', 'en-gb'],
    fallbackLng: 'en-us',
    debug: false,
    load: 'currentOnly',
    lowerCaseLng: 'true',
    // Options for language detector
    detection: {
      order: ['querystring','cookie','path', 'htmlTag'],
      caches: ['cookie'],
      lookupQuerystring: 'culture',            
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>Loading..</h3>
  </div>
)

const {TRACKING_ID} = getConfig()

const tagManagerArgs = {gtmId: TRACKING_ID}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
