import React from 'react'

const FaqLink = () => {
    return (
        <a href="https://orthoclinical.sharepoint.com/sites/TDLFAQ/SitePages/ITHelpdeskHome.aspx" target='__blank'>
            <span className="faq-link">FAQ</span>
        </a>
    )
}

export default FaqLink