import getFilterValueDisplay from "./view-helpers/getFilterValueDisplay"

const TechDocsFilterI18n = ({ className,
    label,
    onMoreClick,
    onRemove,
    onSelect,
    options,
    showMore,
    showSearch,
    onSearch,
    searchPlaceholder, optionsMap,t }) => {
        let showValue = (options.length===1 && options[0].value.length===0) ? false : true;
        //console.log("@@@@ShowValue is ",showValue)
    return (
        <div className="card-body cat-title">
            <h3 style={{display: 'block'}}>{label} </h3>
            {options.length < 1 && <div>{t('No matching options')}</div>}
            {!showValue && <div>{t('No matching options')}</div>}
            {showValue && options.map(option => {
                const checked = option.selected;
                //console.log('option value='+option.value+' tkd value=',optionsMap[option.value])
                
                let altDispValue = optionsMap[option.value]!==undefined ? optionsMap[option.value] : t(option.value)
                if(altDispValue===undefined||altDispValue.length===0)
                 altDispValue=t('Other');
                //console.log('Final AltDisplay Value=',altDispValue)
                return (
                    <label
                        key={`${getFilterValueDisplay(option.value)}`}
                        htmlFor={`example_facet_${label}${getFilterValueDisplay(
                            option.value
                        )}`}
                        className="checkmark-wrap"
                    >
                        {altDispValue} <span> [{option.count}]</span>
                       
                        
                        <input
                            id={`example_facet_${label}${getFilterValueDisplay(
                                option.value
                            )}`}
                            type="checkbox"
                            data-cat={getFilterValueDisplay(option.value)}
                            checked={checked}
                            onChange={() =>
                                checked ? onRemove(option.value) : onSelect(option.value)
                            }
                        />
                        <span className="checkmark">
                        </span>
                    </label>
                )
            })}
            {showMore && <div className="sui-multi-checkbox-facet__view-more" onClick={onMoreClick}>+ {t('More')}</div>}
        </div>
    )
}


export default TechDocsFilterI18n
