import getFilterValueDisplay from "./view-helpers/getFilterValueDisplay"
import Cookies from 'js-cookie'

export default function TechdocsFilterRadio  ({ className,
    label,
    onMoreClick,
    onRemove,
    onSelect,
    options,
    showMore,
    showSearch,
    onSearch,
    searchPlaceholder, optionsMap,t, Cookie_expiry_time }) {
    

        let showValue = (options.length===1 && options[0].value.length===0) ? false : true;
        const selectAllRadio = ( ) => {
            var radios = document.getElementsByTagName('input');
            for (let i = 0; i < radios.length; i++) {
                if (radios[i].type === 'radio' ) {
                  radios[i + 1].checked = false;
                  onRemove();
                    Cookies.set("product",'', { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
                }
            }
            
          }

        //console.log("@@@@ShowValue is ",showValue)
    return (
        
        <div style={{borderBottom: '5px solid #F3F6F7', padding: '30px 0', fontSize: '14px'}} className="card-body cat-title">
            <h3 style={{display: 'block'}}>{label} </h3>
            <input
                value="All"
                type="radio"
                id="allInput"
                checked={!Cookies.get('product')}
                onChange={selectAllRadio}
                /> <span style={{paddingLeft: '5px'}}>{t('All')} </span> 
            {options.length < 1 && <div>{t('No matching options')}</div>}
            {!showValue && <div>{t('No matching options')}</div>}
            {showValue && options.map(option => {
                // const checked = option.selected;
                //console.log('option value='+option.value+' tkd value=',optionsMap[option.value])
                
                let altDispValue = optionsMap[option.value]!==undefined ? optionsMap[option.value] : t(option.value)
                if(altDispValue===undefined||altDispValue.length===0)
                 altDispValue=t('Other');
                //console.log('Final AltDisplay Value=',altDispValue)
                
                return (
                    <div
                        key={`${getFilterValueDisplay(option.value)}`}
                        htmlFor={`example_facet_${label}${getFilterValueDisplay(
                            option.value
                        )}`}
                        className=""
                    >

                           
                        
                        <input
                            id={`example_facet_${label}${getFilterValueDisplay(
                                option.value
                            )}`}
                            type="radio"
                            data-cat={getFilterValueDisplay(option.value)}
                            style={{marginRight: '10px'}}
                            checked={option.value === Cookies.get('product')} 
                            onChange={() => {
                                onRemove()
                                onSelect(option.value)
                                Cookies.set("product", option.value, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
                            }}
                        />
                        {altDispValue} <span> [{option.count}]</span>


                     
                    </div>
                )
            })}
            {showMore && <div className="sui-multi-checkbox-facet__view-more" onClick={onMoreClick}>+ {t('More')}</div>}
        </div>
    )
}




// export default TechDocsFilterRadio
