import styles from '../assets/css/Select.module.css'

export default function SelectAll({resultsSelected, getURL, results, handleOnResultChange, doc, setResultsSelected,t}) {
    
    const select = async () =>{

            const completeRes = await Promise.all(results.map( async (result) => {
                const url = await getURL(result.id.raw, result.filetype.raw, result.name.raw)
                const newItem = { id: result.id.raw, fileType: result.filetype.raw, name: result.name.raw, url: url }
                
                return newItem;
      })
      )
      setResultsSelected(completeRes)
    }

  return (
    <div className={styles.selectText}>
        <span className='testing' onClick={() => select()}>{t('Select All')}</span> 
        <span onClick={() => setResultsSelected([])}>{t('Clear All')}</span>
    </div>
    
  )
}
