import React, {useState} from 'react'
import { SearchBox } from "@elastic/react-search-ui";
import { MdOutlineSearch } from 'react-icons/md'
import styles from '../assets/css/Hero.module.css'
import Cookies from 'js-cookie'

export default function SearchInput({placeHolderText, searchClick, setShow, searchField, setSearchTerm, searchTerm, t }) {
  const [searchError, setSearchError] = useState(null);
  const inputValidation = (evt, onSubmit) => {
  evt.preventDefault();
  if (searchTerm && searchTerm.length > 127) {
      setSearchError(t('search_error_query_too_long'));
      return false;
  }
  else
      if (Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Language") != null) {
          searchClick();
          var actualSearchTerm = ''
          if(searchField.id !== undefined) {
            delete searchField.id;
        }
        
          if (searchTerm && (searchTerm.length === 8 || searchTerm.length === 7)) {
              actualSearchTerm = termToSearch();
              if (actualSearchTerm && actualSearchTerm !== searchTerm) {
                  var options = { shouldClearFilters: false }
                  setSearchTerm(actualSearchTerm, options)
              }
              else
                  onSubmit(evt)
          }
          else
              onSubmit(evt)
          return true;
      }
      else {
          setShow(true);
          return false;
      }
}

const termToSearch = () => {
  const regex = /\d+\s[a-zA-Z0-9]+/gi;
  const str = searchTerm;
  let m;
  let retStr;
  while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
          regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
          retStr = searchTerm.split(" ").join(''); 
      });
      if (retStr)
          return retStr
      else
          return searchTerm
  }
}
  return (
    <SearchBox shouldClearFilters={false} 
      view={({ onChange, value, onSubmit }) => (
        <div style={{marginTop: '15px'}}>
          <form className={styles.form} onSubmit={(evt) => { inputValidation(evt, onSubmit); }}>
          <MdOutlineSearch style={{ color: '#34383C', cursor: 'pointer', width: '1.5em', height: '1.5em' }} onClick={(evt) => { inputValidation(evt, onSubmit); }} />
          <input type="text" value={value} placeholder={placeHolderText} onChange={(evt) => { onChange(evt.currentTarget.value); }}
              name="search" className={styles.input} style={{ width: '100%' }} />
          </form>
        </div>
      )}
  />
  )
}
