import React, { useState } from 'react'
import styles from '../assets/css/SearchResult.Module.css'
import copy from "copy-to-clipboard";
import { FaRegCopy } from 'react-icons/fa';
import Cookies from 'js-cookie'

const CopyFilter = ({ t, filters, isLoggedIn, searchTerm, fullText }) => {

    const [showCpyTxt, setShowCpyTxt] = useState(false);

    const handleCopyFilter = () => {
        // console.log("bk mark filters", filters)
        const countryName = Cookies.get("Techdocs-Country");
        const languageName = Cookies.get("Techdocs-Language");

        const currentUrl = new URL(window.location.href);

        if(searchTerm?.trim()){
            currentUrl.searchParams.set('q', searchTerm);
        }else{
            currentUrl.searchParams.delete('q');
        }

        if(fullText){
            currentUrl.searchParams.set('fullText', "true")
        }else{
            currentUrl.searchParams.delete('fullText');
        }

        currentUrl.searchParams.delete('docId');
        currentUrl.searchParams.set('country', countryName);
        currentUrl.searchParams.set('language', languageName);
        // currentUrl.searchParams.set('bm', JSON.stringify(filters));
        currentUrl.searchParams.set('isauthenticated', isLoggedIn ? 'internal' : 'public');

        setShowCpyTxt(true);
        copy(currentUrl.href);
        setTimeout(() => {
            setShowCpyTxt(false);
        }, 1500)
    }
    return (
        <span>
            <FaRegCopy title={t('Copy Filter')} className='ortho-red cpy-filter' onClick={handleCopyFilter} />
            {showCpyTxt && <span className={styles.sharedText}>{t('Copied')}</span>}
        </span>
    )
}

export default CopyFilter