import config_local from "../config/engine.json";
import config_dev from "../config/engine-dev.json";
import config_qa from "../config/engine-qa.json";
import config_prod from "../config/engine-prod.json";

const config =  (!!process.env.REACT_APP_ENV && (process.env.REACT_APP_ENV.toLowerCase() === 'dev')) ? config_dev :
                (!!process.env.REACT_APP_ENV && (process.env.REACT_APP_ENV.toLowerCase() === 'qa')) ? config_qa :
                (!!process.env.REACT_APP_ENV && (process.env.REACT_APP_ENV.toLowerCase() === 'prod')) ? config_prod :
                config_local;

export function getConfig() {
  if (process.env.NODE_ENV === "test") {
    return {};
  }

  if (config.engineName) return config;
  // if (config.engineName) {
  //   if (process.env.NODE_ENV === 'production')
  //     return config_prod;
  //   if ((process.env.NODE_ENV === 'development'))
  //     return config;
  // }

  if (
    typeof window !== "undefined" &&
    window.appConfig &&
    window.appConfig.engineName
  ) {
    return window.appConfig;
  }

  return {};
}
