import React, { useState } from "react"
import { SearchBox } from "@elastic/react-search-ui";
//import HeroImage from "../assets/images/technical-document-header.png"
import { MdOutlineSearch } from 'react-icons/md'
import Cookies from "js-cookie";
import styles from '../assets/css/Hero.module.css'
import DocumentClassifications from '../components/DocumentClassifications'
import { FaEdit } from "react-icons/fa"
// import TechDocsError from './TechDocsError'
import {
    Facet
  } from "@elastic/react-search-ui";
import { useNavigate } from "react-router-dom";

const Hero = ({ title, caption, placeHolderText, searchField, searchClick, setShow, setSearchTerm, searchTerm, t, Cookie_expiry_time,fullText,handleFullText, expirySearch, handleExpiredDocs, removeFilter, setFilter, userLoggedIn, isEmployee, userInfo, filters }) => {
    const handleShow = () => setShow(true);
    let history = useNavigate();
    const [docClass, setDocClass] = useState([])
    const [altDispValues, setAltDispValues] = useState([])
    const [countrySelected, setCountrySelected] = useState(Cookies.get("Techdocs-Country") ? Cookies.get("Techdocs-Country") : '')
    const [languageSelected, setLanguageSelected] = useState(Cookies.get("Techdocs-Language") ? Cookies.get("Techdocs-Language") : '')
    const [searchError, setSearchError] = useState(null);
    const termToSearch = () => {
        const regex = /\d+\s[a-zA-Z0-9]+/gi;
        const str = searchTerm;
        let m;
        let retStr;
        while ((m = regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            // The result can be accessed through the `m`-variable.
            m.forEach((match, groupIndex) => {
                retStr = searchTerm.split(" ").join(''); 
            });
            if (retStr)
                return retStr
            else
                return searchTerm
        }
    }

    const inputValidation = (evt, onSubmit) => {
        evt.preventDefault();
        if (searchTerm && searchTerm.length > 127) {
            setSearchError(t('search_error_query_too_long'));
            return false;
        }
        else
            if (Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Language") != null) {
                searchClick();
                history('/tdhome/search-result')
                Cookies.set("search-term", searchTerm, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
                var actualSearchTerm = ''
                if(searchField.id !== undefined) {
                    delete searchField.id;
                }
                if (searchTerm && (searchTerm.length === 8 || searchTerm.length === 7)) {
                    actualSearchTerm = termToSearch();
                    if (actualSearchTerm && actualSearchTerm !== searchTerm) {
                        var options = { shouldClearFilters: false }
                        setSearchTerm(actualSearchTerm, options)
                    }
                    else
                        onSubmit(evt)
                }
                else
                    onSubmit(evt)
                return true;
            }
            else {
                setShow(true);
                return false;
            }
    }

    const sortFacetValues = (context, category, categoryClass) => {
        if (!category) return context;
        return {
          ...context,
          facets: {
            ...(context.facets || {}),
            categoryClass: category.map(s => ({
              ...s,
              data: s.data.sort((a, b) => {
                if (a.value > b.value) return 1;
                if (a.value < b.value) return -1;
                return 0;
              })
            }))
          }
        };
      }

      function optionClickAdd(option) {
        let newOptions = []
        docClass.forEach((doc, index) => {
          if (doc.value === option.value) {
            newOptions[index] = { count: option.count, value: option.value, selected: true }
          }
          else {
            newOptions[index] = doc
          }
        })
        setDocClass([...newOptions])
      }
      function optionClickRemove(option) {
        let newOptions = []
        docClass.forEach((doc, index) => {
          doc.value === option.value ? newOptions[index] = option : newOptions[index] = doc
        })
        setDocClass([...newOptions])
      }
    return (
        <main id="hero" className={styles.hero}>
            <section id="RECP001" className={styles.section}>
                <div className="td-hero-container ReCP-001">

                    <div className="row">
                        <div className="col-lg-8 col-md-12 ">
                            <h1 className={styles.techDocHeader}>{title} </h1>
                            
                            <h3 className={styles.moto} dangerouslySetInnerHTML={{ __html: caption }}></h3>
                        </div>
                        <div className="col-lg-4 col-md-12">
                        <div className={styles.country}  style={{ fontSize: '12px' }}>{t('Country')}: {t(countrySelected)} | {t('Language')}: {t(languageSelected)} <FaEdit className="violet" style={{fontSize:"16px", marginLeft:"5px"}} title={t('Edit')} onClick={handleShow} /></div>

                        </div>
                    </div>
                
                    <div>
                        {searchError && <span className="ortho-red">{searchError}</span>}
                        <SearchBox shouldClearFilters={false} /*autocompleteSuggestions={true} searchAsYouType={false} autocompleteResults={true}
                            debounceLength={4}
                            autocompleteMinimumCharacters={4}*/
                            view={({ onChange, value, onSubmit }) => (
                                <div className={styles.searchContainer}>
                                    <div>
                                        <form className={styles.form} onSubmit={(evt) => { inputValidation(evt, onSubmit); }}>
                                        <MdOutlineSearch style={{ color: '#34383C', cursor: 'pointer', width: '1.5em', height: '1.5em' }} onClick={(evt) => { inputValidation(evt, onSubmit); }} />
                                        <input type="text" value={value} placeholder={placeHolderText} onChange={(evt) => { onChange(evt.currentTarget.value); }}
                                            name="search" className={styles.input} id={'td-search'} style={{ width: '100%' }} />
                                        
                                        </form>
                                    </div>
                                    <div>
                                    <button onClick={(evt) => { inputValidation(evt, onSubmit); }} className={styles.button}>{t('Submit')}</button>
                                    </div>
                                    <div>
                                        <label><input type="checkbox" value={fullText} style={{marginRight: '10px'}} onChange={() => handleFullText(!fullText)} checked={fullText}></input>{t('Full Text')}</label>
                                        <div>
                                            {
                                                userLoggedIn &&
                                                isEmployee(userInfo) &&
                                                <label><input type="checkbox" style={{margin: '0 10px 0 0'}} onChange={(e)=>handleExpiredDocs(e, removeFilter, setFilter)} checked={!(filters?.some(obj => obj?.field === "expirationdate" )) || expirySearch}></input>{t("Include Expired Content")}</label>
                                            }
                                        </div>
                                    </div>
                                 </div>
                            )}
                        />
                        
                        <Facet
                            mapContextToProps={context => sortFacetValues(context, context.facets.document_classification, "document_classification")}
                            field="document_classification"
                            optionsMap={altDispValues}
                            label=""
                            show={10}
                            view={DocumentClassifications}
                            filterType="any"
                            optionClickAdd={optionClickAdd}
                            optionClickRemove={optionClickRemove}
                            t={t}
                        />
                    </div>
                    {/* <TechDocsError /> */}
                </div>
            </section>
        </main>
    )
}

export default Hero
