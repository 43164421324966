import React from 'react'
import { FaTimes } from 'react-icons/fa'
import Cookies from 'js-cookie'

const removeCookies = () => {
  if (Cookies.get("Techdocs-Country"))
    Cookies.remove("Techdocs-Country")
  if (Cookies.get("Techdocs-Language"))
    Cookies.remove("Techdocs-Language")

}

const ClearFilters = ({ filters, clearFilters,resetDocClasses,title, setIsClearFilter }) => {
    return (
    <div className="clearFilters indigo">
      <p style={{textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
       // removeCookies()
       setIsClearFilter(false)
        clearFilters(["regioncountrylist","language","audience","usage_type", "expirationdate"])
        resetDocClasses();
      }}>
        {title} 
      </p>
    </div>
  )
}

export default ClearFilters
