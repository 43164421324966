import React from 'react'

const AppRoot = ({children,access_token}) => {
    return (
        <div key={access_token}>
            {children}
        </div>
    )
}

export default AppRoot
